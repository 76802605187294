import * as React from "react"
import { graphql } from "gatsby"
import CanvasLayout from "../../components/layout/CanvasLayout"
import Container from "../../components/layout/Container"
import Type from "../../components/typography/Type"
import { isLoggedIn } from "../../services/auth"
import LoginPrompt from "../../components/app/loginPrompt"
import UserNeedCard from "../../components/cards/UserNeedCard"
import ProcessCard from "../../components/cards/ProcessCard"
import FeatureCard from "../../components/cards/FeatureCard"
import CapabilityCard from "../../components/cards/CapabilityCard"
import MomentCard from "../../components/cards/MomentCard"

import Toggle from "../../components/controls/Toggle"
import Collapsed from "../../components/controls/Collapsed"
import CanvasToolbar from "../../components/canvas/CanvasToolbar"
import Canvas from "../../components/canvas/Canvas"


import { useState } from "react"



export default function Pages( {data}) {

const [isGrid, toggleGrid] = useState(true);
const [isFeatures, toggleFeatures] = useState(true);
const [isNeeds, toggleNeeds] = useState(true);
const [isProcess, toggleProcess] = useState(true);
const [isCapabilities, toggleCapabilities] = useState(true);

const [isOptions, toggleOptions] = useState(false);

const columnWidth = 320;
const horPadding = 0;
const verPadding = 12;

return (
  <>
  <CanvasLayout>
  {isLoggedIn() ? (

<div>
    
    <CanvasToolbar>    
    <div><a href={data.airtable.data.Path}><span className="">&lt; Back to {data.airtable.data.Name}</span></a></div>
    </CanvasToolbar>
    
 <Canvas title={data.airtable.data.Name} introduction={data.airtable.data.Introduction}>  
    
 
    {data.airtable.data.Actors
      ?
      <>
      <div className="w-full grid grid-cols-3 gap-24 p-24">

    {data.airtable.data.Actors.map((ActorsArray, index) => (
      <div className="border p-24">
      <div className="flex flex-row space-x-24 mb-12">
      <div>
      <img 
      src={ActorsArray.data.ImageURL}
      alt={ActorsArray.data.Name}
      className="h-48"
      />
      </div>
      <div>
        <Type type="h1">{ActorsArray.data.Name}</Type>
      </div>    
      </div>
      <div>
      <Type type="p6">{ActorsArray.data.Introduction}</Type>
      </div>
    </div>
  ))}
      </div>
    </>
    :
    <div>No actors yet</div>
  }
    
    
    
    </Canvas>

</div>

    
   ) : (
     <div>
       <LoginPrompt/>
     </div>     
   )}

  </CanvasLayout>
    </>

  )
}

export const Head = () => <title>Framework</title>


export const query = graphql`
  query GetPage($Path: String!) {
    airtable(table: { eq: "Projects" },
    data: { ActorsPath: { eq: $Path } }) {
      data {
        Name
        Path
        Actors {data {
          Name
          ImageURL
          Introduction
        }}
      }
    }
  }
`
